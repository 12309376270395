import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import CardImageTitleDescription from '../../components/card-image-title-description/card-image-title-description';
import { Grid, TextField, Typography, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import { useDebounce } from 'use-debounce';
import WorkingGroup from '../../data/working-groups.json';
import * as _ from 'lodash';

export default function WorkingGroupPage() {
  const [years, setYears] = useState([]);
  const [hasResult, setHasResult] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [filteredWorkingGroup, setfilteredWorkingGroup] = useState({});
  const [value] = useDebounce(searchText, 1000);

  useEffect(() => {
    if (value.length > 0) {
      let _filteredWorkingGroup = {};
      let _hasResult = false;
      years.map((_year) => {
        const _filteredYear = _.filter(WorkingGroup[_year], (_workingGroup) => {
          return (
            _workingGroup.title.toLowerCase().includes(value.toLocaleLowerCase()) ||
            _workingGroup.description.toLowerCase().includes(value.toLowerCase())
          );
        });
        if (_filteredYear.length > 0) {
          _filteredWorkingGroup[_year] = _filteredYear;
          if (!_hasResult) _hasResult = true;
        }
        return false;
      });
      setHasResult(_hasResult);
      setfilteredWorkingGroup(_filteredWorkingGroup);
    } else {
      setHasResult(true);
      setfilteredWorkingGroup(WorkingGroup);
    }
    return () => {};
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    const _years = Object.keys(WorkingGroup);
    setYears(_years.sort((a, b) => b - a));
    setfilteredWorkingGroup(WorkingGroup);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      {
        <>
          <SEO lang='en' title='Working Group' />
          <SectionContainerLayoutWithFilter
            title='WORKING GROUP'
            baseLink='/working-group'
            filters={years}
            isViewAll
            isDivider>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8} md={6}>
                <TextField
                  placeholder='Search Working Group'
                  label='Search Working Group'
                  color='secondary'
                  variant='outlined'
                  fullWidth
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Grid>
              {hasResult ? (
                years.map((_year, index) => {
                  return filteredWorkingGroup[_year] ? (
                    <Grid key={index} item xs={12}>
                      <Typography gutterBottom variant='h6' className='text-uppercase'>
                        <Box my={3}>{_year}</Box>
                      </Typography>
                      <Grid container spacing={3}>
                        {filteredWorkingGroup[_year].map((_data, index) => (
                          <Grid key={index} item xs={12} sm={6} md={3}>
                            <CardImageTitleDescription
                              image={_data.image}
                              title={_data.title}
                              body={_data.description}
                              readMore={`/working-group/${_year}/${_data.url}`}
                              lines={6}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ) : null;
                })
              ) : (
                <Grid item xs={12}>
                  <Box m={8} p={{ xs: 0, sm: 8 }} fullWidth textAlign='center'>
                    <Typography color='textSecondary' variant='button'>
                      No Working Group Found
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </SectionContainerLayoutWithFilter>
        </>
      }
    </Layout>
  );
}
